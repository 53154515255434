import React from 'react';

import { DoubleSelect } from '../slideTemplates';

const input = 'turnover';

const options = [
	{
		slug: '0-50000',
		label: '£0-50k per Annum',
	},
	{
		slug: '50000-100000',
		label: '£50-100k per Annum',
	},
	{
		slug: '100000-250000',
		label: '£100-250k per Annum',
	},
	{
		slug: '250000-500000',
		label: '£250-500k per Annum',
	},
	{
		slug: '500000+',
		label: '£500k+ per Annum',
	},
];

export default ({ slide, data, changeSlide }) => {
	let isNow = slide === 'TurnoverNow',
		title = isNow
			? 'What is your current turnover?'
			: "What's your aim for the next three years?",
		default_now = data[input + '_now'] || '',
		default_goal = data[input + '_future'] || '';

	return (
		<DoubleSelect
			{...{ options, title, isNow, default_now, default_goal, input }}
			prevSlide={(data) => changeSlide('prev', data)}
			nextSlide={(data) => changeSlide('next', data)}
		/>
	);
};
