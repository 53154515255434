import PropTypes from 'prop-types';
import React from 'react';

const SelectionItem = ({
	input,
	text,
	isNow,
	value,
	selected_now,
	selected_goal,
	changeData,
}) => {
	let id = input + (isNow ? '_now' : '_goal') + '_' + value,
		selectedNow = selected_now === value ? 'selected-now' : '',
		selectedGoal = selected_goal === value ? ' selected-goal' : '';

	return (
		<p
			htmlFor={id}
			className={selectedNow + selectedGoal + ' clickable'}
			onClick={() => changeData(value)}
		>
			<span>{text}</span>
		</p>
	);
};

SelectionItem.propTypes = {
	input: PropTypes.string.isRequired,
	text: PropTypes.string,
	isNow: PropTypes.bool.isRequired,
	value: PropTypes.string.isRequired,
	selected_now: PropTypes.string.isRequired,
	selected_goal: PropTypes.string,
	changeData: PropTypes.func.isRequired,
};

export default SelectionItem;
