import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-svg';

class Question extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showSubQuestion: false,
			answered: 'answer' in props,
			newAnswer: '',
		};
	}

	mainAnswer(answer, e) {
		this.props.changeMainAnswer(this.props.field, answer);
		this.setState({
			answered: true,
		});
		if (!!this.props.subQuestion) this.showSubQuestion();
	}

	subAnswer(answer, e) {
		this.props.changeSubAnswer(this.props.field, answer);
		this.setState({
			showSubQuestion: false,
		});
	}

	showSubQuestion() {
		this.setState({
			showSubQuestion: true,
		});
	}

	render() {
		let isSubQuestion =
				!!this.props.subQuestion &&
				this.state.showSubQuestion &&
				this.props.answer === this.props.subQuestion.condition,
			subAnswerQuestion = isSubQuestion
				? !!this.state.newAnswer
					? this.state.newAnswer
					: this.props.subQuestion.answer
				: false;

		return (
			<div className="question-item">
				<div className="question-box">
					<p className="">{this.props.text}</p>
					{!!this.props.subQuestion &&
						!!this.props.subQuestion.answer &&
						this.props.subQuestion.condition === this.props.answer && (
							<p className="text-light-gray">
								<em>{this.props.subQuestion.answer}</em>
							</p>
						)}
				</div>
				<div className="answer-box">
					<span
						className={
							'clickable true ' +
							(this.state.answered && this.props.answer === 'true'
								? 'selected'
								: '')
						}
						onClick={this.mainAnswer.bind(this, true)}
					>
						<SVG
							src={'/images/icon-check.svg'}
							style={{
								display: 'block',
								width: '15px',
							}}
						/>
					</span>
					<span
						className={
							'clickable false ' +
							(this.state.answered && this.props.answer === 'false'
								? 'selected'
								: '')
						}
						onClick={this.mainAnswer.bind(this, false)}
					>
						<SVG
							src={'/images/icon-cross.svg'}
							style={{
								display: 'block',
								width: '15px',
							}}
						/>
					</span>
				</div>
				{isSubQuestion && (
					<div className="sub-question">
						<div className="sub-question-title">
							<p className="text-center mbot0">{this.props.subQuestion.text}</p>
						</div>
						<ul className="no-list text-left">
							{this.props.subQuestion.answers.map((answer) => {
								return (
									<li
										className="clickable"
										onClick={this.subAnswer.bind(this, answer)}
									>
										<span>
											<i
												className={
													'icon icon-check ' +
													(answer === subAnswerQuestion
														? 'text-light-green'
														: 'text-transparent')
												}
											>
												{' '}
											</i>
											{answer}
										</span>
									</li>
								);
							})}
						</ul>
					</div>
				)}
			</div>
		);
	}
}

Question.propTypes = {
	field: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	answer: PropTypes.string,
	subQuestion: PropTypes.object,
	changeMainAnswer: PropTypes.func.isRequired,
	changeSubAnswer: PropTypes.func.isRequired,
};

export default Question;
