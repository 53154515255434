import PropTypes from 'prop-types';
import React from 'react';
import { navigate } from 'gatsby';

import { Text } from '../slideTemplates';

const Done = ({ history, ...props }) => {
	return (
		<Text
			title={<span className="text-white">All done!</span>}
			buttonText="Send"
			content={
				<div>
					<p className="text-center mbot30">
						Thanks for taking the time to complete this, it will help us ensure
						that we connect you with the right support at TownSq.
					</p>
					<p className="text-center mbot30">
						We look forward to chatting soon.
					</p>
				</div>
			}
			nextSlide={() => navigate('/')}
		/>
	);
};

Done.propTypes = {
	prevSlide: PropTypes.func.isRequired,
	nextSlide: PropTypes.func.isRequired,
};

export default Done;
