import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'react-svg';
import { findWhere, findKey } from 'underscore';

import QuestionItem from './components/Question';

export default class extends React.Component {
	static propTypes = {
		title: PropTypes.string.isRequired,
		questions: PropTypes.array.isRequired,
		prevSlide: PropTypes.func.isRequired,
		nextSlide: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		let completedQuestions = props.questions.filter(
			(question) => 'answer' in question
		);

		this.state = {
			questions: props.questions,
			completed: completedQuestions.length === props.questions.length,
			answers: {},
		};
	}

	changeMainAnswer = (field, value) => {
		let { questions } = this.state,
			key = findKey(questions, (question) => question.field === field);
		questions[key].answer = value.toString();

		let completedQuestions = questions.filter(
			(question) => 'answer' in question
		);
		this.setState({
			questions,
			completed: completedQuestions.length === questions.length,
			answers: {
				...this.state.answers,
				[field]: value,
			},
		});
	};

	changeSubAnswer = (field, value) => {
		let { questions } = this.state,
			key = findKey(questions, (question) => question.field === field);
		questions[key].subQuestion.answer = value;

		let completedQuestions = questions.filter(
			(question) => 'answer' in question
		);
		this.setState({
			questions,
			completed: completedQuestions.length === questions.length,
			answers: {
				...this.state.answers,
				[field + '_follow']: value,
			},
		});
	};

	render() {
		let { completed, questions, answers } = this.state;

		return (
			<div>
				<div className="slide-wrapper">
					<header className="bg-deep-blue p20">
						<h4 className="text-white text-center mtop0 mbot0">
							{this.props.title}
						</h4>
					</header>

					<div className="inner bg-light-blue bg-slightly-transparent">
						{questions.map((question) => {
							return (
								<QuestionItem
									{...question}
									key={'question-' + question.field}
									changeMainAnswer={this.changeMainAnswer}
									changeSubAnswer={this.changeSubAnswer}
								/>
							);
						})}
					</div>

					<footer className="bg-deep-blue p20 lead-capture__footer">
						<p className="lead mbot0 text-white">
							<button
								onClick={() => this.props.prevSlide(answers)}
								className={'lead-capture__ctrl'}
							>
								<SVG
									src={'/images/chevron-right.svg'}
									style={{
										transform: 'rotate(180deg)',
									}}
								/>
							</button>

							<button
								onClick={() => this.props.nextSlide(answers)}
								disabled={!this.state.completed}
								className={
									'lead-capture__ctrl' +
									(!this.state.completed ? ' disabled' : '')
								}
							>
								<SVG src={'/images/chevron-right.svg'} />
							</button>
						</p>
						<div className="w-clearfix" />
					</footer>
				</div>
			</div>
		);
	}
}
