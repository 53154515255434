import PropTypes from 'prop-types';
import React from 'react';
import RadioGroup from 'react-radio-group';

import { DoubleSelect } from '../slideTemplates';

const input = 'staff';

const options = [
	{
		slug: '0-5',
		label: '0 to 5 people',
	},
	{
		slug: '6-10',
		label: '6 to 10 people',
	},
	{
		slug: '11-25',
		label: '11 to 25 people',
	},
	{
		slug: '26-50',
		label: '26 to 50 people',
	},
	{
		slug: '51+',
		label: '51+ people',
	},
];

export default ({ slide, data, changeSlide }) => {
	let isNow = slide === 'StaffNow',
		title = isNow
			? 'How many people are in your team?'
			: 'And how many do you hope to have in three years?',
		default_now = data[input + '_now'] || '',
		default_goal = data[input + '_future'] || '';

	return (
		<DoubleSelect
			{...{ options, title, isNow, default_now, default_goal, input }}
			prevSlide={(data) => changeSlide('prev', data)}
			nextSlide={(data) => changeSlide('next', data)}
		/>
	);
};
