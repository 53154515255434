import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'react-svg';
import RadioGroup from 'react-radio-group';

import SelectionItem from './components/Selection';

export default class extends React.Component {
	static propTypes = {
		options: PropTypes.array.isRequired,
		input: PropTypes.string.isRequired,
		isNow: PropTypes.bool.isRequired,
		default_now: PropTypes.string,
		default_goal: PropTypes.string,
		changeData: PropTypes.func.isRequired,
		prevSlide: PropTypes.func.isRequired,
		nextSlide: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);
		this.state = {
			[props.input + '_now']: props.default_now,
			[props.input + '_goal']: props.default_goal,
		};
	}

	changeData = (value) => {
		let field = this.props.isNow
			? this.props.input + '_now'
			: this.props.input + '_goal';
		this.setState({
			[field]: value,
		});
	};

	render() {
		let { title, input, options, isNow } = this.props,
			selected_now = this.state[input + '_now'],
			selected_goal = this.state[input + '_goal'],
			completed = (isNow && selected_now) || (!isNow && selected_goal);

		let passableData = {
			[input + (isNow ? '_now' : '_goal')]: isNow
				? selected_now
				: selected_goal,
		};

		// console.log( this.state, this.props )

		return (
			<div className="slide-wrapper">
				<header className="bg-deep-blue p20">
					<h4
						className="text-white text-center mtop0 mbot0"
						dangerouslySetInnerHTML={{ __html: title }}
					/>
				</header>
				<div className="inner bg-mid-blue bg-slightly-transparent">
					<div className="vertical-selection colour-scheme-blue">
						{options.map((option) => {
							return (
								<SelectionItem
									{...{ input, isNow, selected_now, selected_goal }}
									key={'option-' + option.slug}
									value={option.slug}
									text={option.label}
									changeData={this.changeData}
								/>
							);
						})}
					</div>
				</div>
				<footer className="bg-deep-blue p20 lead-capture__footer">
					<p className="lead mbot0 text-center text-white">
						<button
							onClick={() => this.props.prevSlide(passableData)}
							className={'lead-capture__ctrl'}
						>
							<SVG
								src={'/images/chevron-right.svg'}
								style={{
									transform: 'rotate(180deg)',
								}}
							/>
						</button>

						<button
							onClick={() => completed && this.props.nextSlide(passableData)}
							disabled={!completed}
							className={'lead-capture__ctrl' + (!completed ? ' disabled' : '')}
						>
							<SVG src={'/images/chevron-right.svg'} />
						</button>
					</p>
					<div className="w-clearfix" />
				</footer>
			</div>
		);
	}
}
