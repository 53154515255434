import React from 'react';

import { Text } from '../slideTemplates';

export default ({ changeSlide, boardCall }) => {
	const content = boardCall ? (
		<div>
			<p className="text-center mbot30">
				If you are looking to become a member, we have a quick exercise to
				complete to help us make sure you get the best support.
			</p>
			<p className="text-center mbot30">Hit the button below to get started.</p>
		</div>
	) : (
		<div>
			<p className="text-center mbot30">
				Thanks for getting in touch, we will get back to you within 48 hours.
			</p>
			<p className="text-center mbot30">
				If you are looking to become a member, we have a quick exercise to
				complete to help us make sure you get the best support.
			</p>
			<p className="text-center mbot30">Hit the button below to get started.</p>
		</div>
	);

	return (
		<Text
			title={null}
			content={content}
			buttonText="Get started"
			nextSlide={() => changeSlide('next')}
		/>
	);
};
