import React from 'react';
import SVG from 'react-svg';
// import ReactSlider from 'react-slider';
// import Slider from 'react-slide';
import Slider from 'rc-slider';

import 'rc-slider/assets/index.css';

const input = 'stage';

const labels = {
	100: 'Business is over three years old',
	80: 'Business is under three years old',
	60: 'Business is under a year old',
	40: 'Recently launched a business',
	20: 'Preparing to launch a business',
	0: 'Thinking about launching a business',
};

const tooltipStyles = {
	position: 'absolute',
	top: '50%',
	transform: 'translateY(-50%)',
	width: '150px',
	left: '40px',
};

const handleStyles = {
	background: 'none',
	height: 0,
	margin: '',
};

const Handle = (props) => (
	<div {...props}>
		<span style={tooltipStyles}>{ting[props.value]}</span>
	</div>
);

export default class extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			stage: input in props.data ? props.data[input] : labels['0'],
		};
	}

	changeData = (value) => {
		this.setState({
			stage: value,
		});
	};

	render() {
		return (
			<div className="slide-wrapper">
				<header className="bg-mid-blue p20">
					<h4 className="text-white text-center mtop0 mbot0">
						Drag the marker to show your current status
					</h4>
				</header>
				<div className="inner bg-light-blue bg-slightly-transparent ptop40">
					<div className="range-slider">
						<div className="range-slider__inner">
							<Slider
								vertical
								min={0}
								marks={labels}
								step={null}
								onChange={(index) => {
									let val = labels[index];

									this.changeData(val);
								}}
								handle={(e) => {
									let val = labels[e.value];

									return (
										<div
											className={'rc-slider-bl-handle'}
											style={{
												bottom: `${e.value}%`,
											}}
										>
											<div className={'rc-slider-handle'} />
											<div className="rc-slider-bl-handle__label">{val}</div>
										</div>
									);
								}}
								defaultValue={0}
							/>
						</div>
					</div>

					<div className="w-clearfix" />
				</div>
				<footer className="bg-deep-blue p20 lead-capture__footer">
					<p className="lead mbot0 text-white">
						Slide up & down to choose
						<button onClick={() => this.props.changeSlide('next', this.state)}>
							<SVG src={'/images/chevron-right.svg'} />
						</button>
					</p>
				</footer>
			</div>
		);
	}
}
