import React, { Component, Fragment } from 'react';

class Details extends Component {
	constructor() {
		super();

		this.state = {
			formData: {},
			loading: false,
		};
	}

	saveData = (e) => {
		e.preventDefault();
		let formData = {
			name: this.refs.name.value,
			email: this.refs.email.value,
			phone: this.refs.phone.value,
			notes: this.refs.notes.value,
		};
		this.setState({ formData });
		this.props.changeSlide('next', formData);
	};

	render() {
		let {
			loading,
			formData: { name, email, phone, notes, honeypot, formValid },
		} = this.state;

		let error;

		return (
			<Fragment>
				<div className="pop-up-intro">
					<h3 className="pop-up-form-title">
						{true ? 'Register Interest' : 'Book a coffee'}
					</h3>
					<p className="pop-up-p">
						If you’re interested in learning more about how we can support you
						to grow your idea, let us know and we will be in touch.
					</p>
				</div>

				<div className="form-block-2 w-form">
					<form onSubmit={this.saveData}>
						<div className="pop-up-form-row w-row">
							<div className="pop-up-form-row-col1 w-col w-col-1 w-col-small-1 w-col-tiny-1">
								<div className="icomoon icon icomoon icon-user" />
							</div>
							<div className="pop-up-form-row-col2 w-col w-col-11 w-col-small-11 w-col-tiny-11">
								<input
									ref={'name'}
									type="text"
									className="name-field1 w-input"
									placeholder="Name"
									required
								/>
							</div>
						</div>
						<div className="pop-up-form-row w-row">
							<div className="pop-up-form-row-col1 w-col w-col-1 w-col-small-1 w-col-tiny-1">
								<div className="icomoon icon icomoon icon-envelope" />
							</div>
							<div className="pop-up-form-row-col2 w-col w-col-11 w-col-small-11 w-col-tiny-11">
								<input
									ref={'email'}
									type="email"
									className="email-field-1 w-input"
									placeholder="Email"
									required
								/>
							</div>
						</div>
						<div className="pop-up-form-row w-row">
							<div className="pop-up-form-row-col1 w-col w-col-1 w-col-medium-1 w-col-small-1 w-col-tiny-1">
								<div className="icomoon icon icomoon icon-telephone" />
							</div>
							<div className="pop-up-form-row-col2 w-col w-col-11 w-col-medium-11 w-col-small-11 w-col-tiny-11">
								<input
									ref={'phone'}
									type="text"
									className="phone-field-1 w-input"
									placeholder="Phone Number"
								/>
							</div>
						</div>
						<div className="pop-up-form-row-message w-row">
							<div className="pop-up-form-row-message-col1 w-col w-col-1 w-col-small-1 w-col-tiny-1">
								<div className="icomoon icon-message icomoon icon-document" />
							</div>
							<div className="pop-up-form-row-message-col2 w-col w-col-11 w-col-small-11 w-col-tiny-11">
								<textarea
									ref={'notes'}
									placeholder="How can we help you?"
									className="message-field-1 w-input"
								/>
							</div>
						</div>

						<input type="hidden" ref={'honeypot'} />

						<input
							type="submit"
							defaultValue="Send"
							className="submit-button-4 w-button"
							// disabled={!formValid}
						/>
					</form>

					{error && (
						<div
							className="w-form-fail"
							style={{ marginLeft: '15px', marginRight: '15px' }}
						>
							<div className="text-block-5">{error}</div>
						</div>
					)}
				</div>
			</Fragment>
		);
	}
}

export default Details;

// import React, { Component } from 'react'
// import PropTypes from 'prop-types'
//
// import { Loading } from '../../../components'
//
//
// class Details extends Component {
//
//     constructor() {
//         super();
//         this.state = {
//             formData: {},
//             loading: false
//         }
//     }
//
//     saveData = (e) => {
//         e.preventDefault();
//         let formData = {
//             name: this.refs.name.value,
//             email: this.refs.email.value,
//             phone: this.refs.phone.value,
//             notes: this.refs.notes.value,
//         };
//         this.setState({ formData });
//         this.props.changeSlide('next', formData)
//     }
//
//     render() {
//
//         let { loading } = this.state;
//
//         return (
//             <div className="slide-holder">
//                 <div id="login-slide" className={"slide-wrapper hover"}>
//                     <header className="ptop20 pbot20">
//                         <h4 className="mtop0 mbot0">If you're interested in joining TownSq, or learning more - get in touch</h4>
//                     </header>
//                     <div className="inner no-footer">
//                         <form onSubmit={this.saveData}>
//                             { loading
//                                 ? <Loading classes="ptop30 pbot30" />
//                                 : <div>
//                                     <div className="register-email">
//
//                                         <div className="input">
//                                             <i className="icon-user" />
//                                             <input type="text" name="name" ref="name" placeholder="Name" className="form-control" required />
//                                             <div className="w-clearfix" />
//                                         </div>
//
//                                         <div className="input">
//                                             <i className="icon-envelope" />
//                                             <input type="text" name="email" ref="email" placeholder="Email" className="form-control" required />
//                                             <div className="w-clearfix" />
//                                         </div>
//
//                                         <div className="input">
//                                             <i className="icon-telephone2" />
//                                             <input type="text" name="phone" ref="phone" placeholder="Phone Number" className="form-control" />
//                                             <div className="w-clearfix" />
//                                         </div>
//
//                                         <div className="input">
//                                             <i className="icon-document" />
//                                             <textarea name="notes" ref="notes" placeholder="Notes" className="form-control" />
//                                             <div className="w-clearfix" />
//                                         </div>
//
//                                     </div>
//                                     <div className="w-clearfix" />
//                                     <footer className="bg-deep-blue text-center text-white">
//                                         <button type="submit" className="p20 btn btn-none btn-block">Send</button>
//                                     </footer>
//                                 </div>
//                             }
//                         </form>
//                     </div>
//                 </div>
//             </div>
//         )
//     }
//
// }
//
// export default Details;
