import PropTypes from 'prop-types';
import React from 'react';

export default class extends React.Component {
	static propTypes = {
		prevSlide: PropTypes.func,
		nextSlide: PropTypes.func,
		title: PropTypes.object.isRequired,
		content: PropTypes.object.isRequired,
	};

	render() {
		let { buttonText, nextSlide, content, title } = this.props;

		return (
			<div>
				<div className="slide-wrapper">
					{title && (
						<header className="bg-mid-blue p20">
							<h4 className="text-center mtop0 mbot0">{title}</h4>
						</header>
					)}
					<div className="inner bg-light-blue bg-slightly-transparent p20">
						{content}
					</div>
					<footer className="bg-deep-blue text-center text-white">
						<button onClick={nextSlide} className="p20 btn btn-none btn-block">
							{buttonText}
						</button>
					</footer>
				</div>
			</div>
		);
	}
}
