import React, { Fragment, Component } from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import axios from 'axios';

import * as Slides from '../components/LeadCapture/slides';

import site from '../config/site';
import { getFormattedDate } from '../utils/dateFormatting';
import { MetaData, QuickForm } from '../components';

const slides = [
	'Details',
	'Welcome',
	'WhatStage',
	'TurnoverNow',
	'TurnoverGoal',
	'StaffNow',
	'StaffGoal',
	'Operations',
	'Clients',
	'Ambition',
	'AllDone',
];

const SlideSwitcher = (props) => {
	let Slide = Slides[props.slide],
		style = props.active ? {} : { display: 'none' };

	return (
		<div style={style} key={'slide-' + props.slide}>
			<Slide {...props} />
		</div>
	);
};

class LeadCapture extends Component {
	constructor(props) {
		super(props);

		let passedUser = {},
			createdAt = getFormattedDate(),
			page = props.location.pathname,
			state = props.location.state;

		if (state && 'email' in state) {
			let { email, name, business, enquiry } = state;
			if (!!email) passedUser.email = email;
			if (!!name) passedUser.name = name;
			if (!!business) passedUser.business = business;
			if (!!enquiry) passedUser.notes = enquiry;
			if (!!state.csrf) passedUser.csrf = state.csrf;
			if (!!state.page) page = state.page;
			if (!!state.createdAt) createdAt = state.createdAt;
		}

		this.state = {
			slide_number: 'email' in passedUser ? 2 : 1,
			direction: 'next',
			triggerNewUpdate: false,
			completedData: {
				createdAt,
				page,
				...passedUser,
			},
		};
	}

	componentDidUpdate() {
		let { triggerNewUpdate, completedData } = this.state;
		if (!triggerNewUpdate) return;
		completedData.updatedAt = getFormattedDate();

		axios
			.get(site.leadCaptureUrl, {
				params: completedData,
			})
			.catch((e) => {
				console.error(e);
			});

		this.setState({ triggerNewUpdate: false });
	}

	increaseSlideNumber = () => {
		let slide_number = +this.state.slide_number + 1;
		if (slides.length < slide_number) return;
		this.setState({ slide_number });
	};

	decreaseSlideNumber = () => {
		let slide_number = this.state.slide_number - 1;
		if (slide_number < 1) return;
		this.setState({ slide_number });
	};

	slideChange = (direction = 'next', returnedData = {}) => {
		if (direction === 'next') {
			this.increaseSlideNumber();
		} else {
			this.decreaseSlideNumber();
		}
		this.setState({ direction });
		if (returnedData) this.updateUser(returnedData);
	};

	updateUser = (completedData) => {
		this.setState({
			completedData: {
				...this.state.completedData,
				...completedData,
			},
			triggerNewUpdate: true,
		});
	};

	render() {
		let { slide_number, completedData } = this.state,
			slideIndex = slides[slide_number - 1 || 0];

		let multi = true;

		const boardCall = this.props.location.pathname === '/board-call';

		// if(!this.props.location.state && !boardCall) {
		//     navigate('/404');
		//
		//     return null;
		// }

		return (
			<div
				className="slide-transition ptop100 pbot100"
				id={slideIndex + '-slide'}
			>
				<MetaData title={'Register'} />

				<CSSTransitionGroup
					transitionName="slide"
					component="fieldset"
					transitionEnter={true}
					transitionLeave={true}
					transitionEnterTimeout={5000}
					transitionLeaveTimeout={3000}
				>
					{multi ? (
						slides.map((slideName) => (
							<SlideSwitcher
								slide={slideName}
								active={slideName === slideIndex}
								changeSlide={this.slideChange}
								data={completedData}
								onComplete={this.submit}
								boardCall={boardCall}
							/>
						))
					) : (
						<SlideSwitcher
							slide={slideIndex}
							active={true}
							changeSlide={this.slideChange}
							data={completedData}
							boardCall={boardCall}
						/>
					)}
					{/*{ !props.location.state.name ? (*/}
					{/*<QuickForm*/}
					{/*page={'/lead'}*/}
					{/*onSend={() => setModal(false)}*/}
					{/*/>*/}
					{/*) : (*/}
					{/*<Fragment>*/}
					{/*{*/}
					{/*multi*/}
					{/*? slides.map( slideName => <SlideSwitcher slide={slideName} active={slideName === slideIndex} changeSlide={this.slideChange} data={completedData} onComplete={this.submit} boardCall={boardCall} /> )*/}
					{/*: <SlideSwitcher slide={slideIndex} active={true} changeSlide={this.slideChange} data={completedData} boardCall={boardCall} />*/}
					{/*}*/}
					{/*</Fragment>*/}
					{/*) }*/}
				</CSSTransitionGroup>
			</div>
		);
	}
}

export default LeadCapture;
