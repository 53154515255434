import React from 'react';

import { Questions } from '../slideTemplates';

const originalQuestions = [
	{
		field: 'clients_lined_up',
		text: 'Do you have customers lined up?',
		/*subQuestion: {
            condition: 'true',
            text: 'Do you have clients lined up?',
            answers: [
                "Not as many as I'd like",
                "Just the right amount",
                "More than I can cope with"
            ]
        }*/
	},
	{
		field: 'vat_registered',
		text: 'Is your company VAT registered?',
		/*subQuestion: {
            condition: 'true',
            text: 'Are you VAT registered or do you expect to be in the next 12 months?',
            answers: [
                "I already am",
                "Definitely will be",
                "I might be"
            ]
        }*/
	},
	{
		field: 'trading_internationally',
		text: 'Do you plan on trading internationally?',
	},
];

export default class extends React.Component {
	constructor(props) {
		super(props);
		let questions = originalQuestions.map((question) => {
			if (question.field in props.data)
				question.answer = props.data[question.field];
			if (question.field + '_follow' in props.data)
				question.subQuestion.answer = props.data[question.field + '_follow'];
			return question;
		});
		this.state = { questions };
	}

	render() {
		return (
			<Questions
				prevSlide={(data) => this.props.changeSlide('prev', data)}
				nextSlide={(data) => this.props.changeSlide('next', data)}
				title="Here are a few questions about your sales status"
				questions={this.state.questions}
			/>
		);
	}
}
