import React from 'react';

import { Questions } from '../slideTemplates';

const originalQuestions = [
	{
		field: 'company_registered',
		text: 'Have you registered your company?',
	},
	{
		field: 'bank_account',
		text: 'Do you have a business bank account?',
	},
	{
		field: 'employ_someone',
		text: 'Do you currently have staff?',
	},
];

export default class extends React.Component {
	constructor(props) {
		super(props);
		let questions = originalQuestions.map((question) => {
			if (question.field in props.data)
				question.answer = props.data[question.field];
			if (question.field + '_follow' in props.data)
				question.subQuestion.answer = props.data[question.field + '_follow'];
			return question;
		});
		this.state = { questions };
	}

	render() {
		return (
			<Questions
				prevSlide={(data) => this.props.changeSlide('prev', data)}
				nextSlide={(data) => this.props.changeSlide('next', data)}
				title="Here are a few questions about your operational status"
				questions={this.state.questions}
			/>
		);
	}
}
